.dropzone {
  height: calc(100vh - 64px - 16px);
  border: dashed 2px #999;
  box-sizing: border-box;
  margin: 8px;
  padding: 8px;
}

.uploading {
  background-color: #ddd;
}
